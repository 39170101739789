exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-500-js": () => import("./../../../src/pages/500.js" /* webpackChunkName: "component---src-pages-500-js" */),
  "component---src-pages-checkout-js": () => import("./../../../src/pages/checkout.js" /* webpackChunkName: "component---src-pages-checkout-js" */),
  "component---src-pages-giving-areas-js": () => import("./../../../src/pages/giving-areas.js" /* webpackChunkName: "component---src-pages-giving-areas-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legacy-planning-js": () => import("./../../../src/pages/legacy-planning.js" /* webpackChunkName: "component---src-pages-legacy-planning-js" */),
  "component---src-pages-mdx-frontmatter-slug-js": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js" */),
  "component---src-pages-other-ways-to-give-js": () => import("./../../../src/pages/other-ways-to-give.js" /* webpackChunkName: "component---src-pages-other-ways-to-give-js" */)
}

